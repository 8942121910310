import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO
      title="404 Page not found
"
    />
    {/* <!--================Home Banner Area =================--> */}
    <section className="banner_area banner_area_09">
      <div className="banner_inner d-flex align-items-center">
        <div
          className="overlay bg-parallax"
          data-stellar-ratio="0.9"
          data-stellar-vertical-offset="0"
          data-background=""
        ></div>
        <div className="container">
          <div className="banner_content text-center">
            <h2>404 Page not Found</h2>
          </div>
        </div>
      </div>
    </section>
    {/* <!--================End Home Banner Area =================--> */}

    {/* <!--================404 Area =================--> */}
    <section className="blog_area single-post-area p_120">
      <div className="container">
        <div className="main_title">
          <h2>Oops !!!</h2>
          <p>
            Navigate to <Link to="/">home page</Link> now
          </p>
        </div>
      </div>
    </section>
    {/* <!--================404 Area =================--> */}
  </Layout>
)

export default NotFoundPage
